* {
  box-sizing: border-box;
  font-family: 'Karla', sans-serif !important;
}

html,
body,
#root {
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'Karla', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.shadow-xs {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.shadow-2xl {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.shadow-outline {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.shadow-none {
  box-shadow: none;
}

// Custom buttons styles for users site
.linkme_btn.btn {
  border-radius: 0;
  height: 60px;
  font-size: 1rem;
  box-shadow: none;
  border: 1px solid #6d6b6b;
  &.rounded {
    border-radius: 0.5rem;
  }
  &.fullrounded {
    border-radius: 2rem;
  }

  &.outline {
    background: transparent;
    border-width: 2px;
  }

  &.soft-shadow {
    box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.1);
  }

  &.hard-shadow {
    box-shadow: 4px 4px 0 0 #736c6c;
  }
}
