.site {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    .title {
      margin: 1rem 0;
    }

    .bio {
      white-space: pre-line;
    }
  }

  &__links {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;

    .link {
      margin-bottom: 1rem;
    }
  }
}
