.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  animation: spinner 2.5s infinite linear both;
  left: calc(50% - 20px);
  top: calc(50% - 20px);

  .spinner-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: spinner-dot 2s infinite ease-in-out both;
  }

  .spinner-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: red;
    border-radius: 100%;
    animation: spinner-dot-before 2s infinite ease-in-out both;
  }

  .spinner-dot:nth-child(1) {
    animation-delay: -1.1s;
  }

  .spinner-dot:nth-child(2) {
    animation-delay: -1s;
  }

  .spinner-dot:nth-child(3) {
    animation-delay: -0.9s;
  }

  .spinner-dot:nth-child(4) {
    animation-delay: -0.8s;
  }

  .spinner-dot:nth-child(5) {
    animation-delay: -0.7s;
  }

  .spinner-dot:nth-child(6) {
    animation-delay: -0.6s;
  }

  .spinner-dot:nth-child(1):before {
    animation-delay: -1.1s;
  }

  .spinner-dot:nth-child(2):before {
    animation-delay: -1s;
  }

  .spinner-dot:nth-child(3):before {
    animation-delay: -0.9s;
  }

  .spinner-dot:nth-child(4):before {
    animation-delay: -0.8s;
  }

  .spinner-dot:nth-child(5):before {
    animation-delay: -0.7s;
  }

  .spinner-dot:nth-child(6):before {
    animation-delay: -0.6s;
  }
}

@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-dot-before {
  50% {
    transform: scale(0.4);
  }

  100%,
  0% {
    transform: scale(1);
  }
}
